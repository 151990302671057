.abard{
  position: relative;
  padding-top: 2px;
  .icon{
    &:before{
      content: '';
      width: 16px;
      height: 15px;
      background: url(./hqrk.png) no-repeat;
      display: inline-block;
      vertical-align: middle;

    }
   
    margin-right: 6px;
  }
  .sh:before{
    background-position: -19px 0;
  }
  .mmd:before{
    background-position: -38px 0;
  }
  .cj:before{
    background-position: -58px 0;
  }
  .yl:before{
    background-position: -77px 0;
  }
  .jg:before{
    background-position: -95px 0;
  }
  .wdm:before{
    background-position: -115px 0;
  }  

  .red{
    color: #f00;
  }

  .refresh{
    position: absolute;
    right: 0;
    top: 0px;
    width: 57px;
    height: 21px;
    background: url(./hqrk.png) no-repeat -133px 0;
    &:hover{
      filter: brightness(0.95);
    }
  }
}