.fscj_jt_up{
  display: inline-block;
  width: 8px;
  height: 12px;
  background: url(./up.png) no-repeat;
  vertical-align: middle;
  margin-left: 3px;
}
.fscj_jt_down{
  display: inline-block;
  width: 8px;
  height: 12px;
  background: url(./down.png) no-repeat;
  vertical-align: middle;
  margin-left: 3px;
}