.breadcrumb{
  padding: 5px 0 0 5px;
  .breadcrumb_item{
    &::after{
      content: '>';
      margin: 0 5px;
    }
    &:last-child{
      &::after{
        content: '';
      }      
    }
  }
}