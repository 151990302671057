.layout_lr{
  .left{
    float: left;
  }
  .right{
    float: right;
  }
  &::after{
    height: 0;
    content: '\20';
    clear: both;
    display: block;
  }
}

.layout_m2split{
  .layout_m2split_sider{
    float: left;
    width: 390px;
  }
  .layout_m2split_sider:last-child{
    float: right;
  }
  &::after{
    height: 0;
    content: '\20';
    clear: both;
    display: block;
  }  
}

.layout_sm{
  .layout_sm_sider{
    float: left;
    width: 200px;
  }
  .layout_sm_main{
    float: right;
    width: 790px;
  }
  &::after{
    height: 0;
    content: '\20';
    clear: both;
    display: block;
  }  
}

.layout_smm{
  .layout_smm_sider{
    float: left;
    width: 200px;
  }
  .layout_smm_main{
    float: left;
    width: 390px;
    margin-left: 10px;
  }
  &::after{
    height: 0;
    content: '\20';
    clear: both;
    display: block;
  }  
}


.pagetitle{
  font-size: 18px;
  font-family: 'Microsoft Yahei';
  color: #494949;
  border-bottom: 4px solid #2f5895;
  margin-top: 10px;
  position: relative;

  .t{
    padding: 6px 15px;    
    display: inline-block;
    background-color: #fff;
    margin-bottom: -3px;
    font-weight: 500;
  }
  a{
    color: #494949;
    &:hover{
      color: #ea5404;
    }
  }
  &::before{
    content: '';
    left: 0;
    top: 0;
    position: absolute;
    width: 7px;
    height: 7px;
    border-left: 3px solid #FC4802;
    border-top: 3px solid #FC4802;
  }
}

.layout_m_ms{
  .layout_m_ms_m{
    float: left;
    width: 580px;
  }
  .layout_m_ms_s{
    float: right;
    width: 200px;
  }  
  &::after{
    height: 0;
    content: '\20';
    clear: both;
    display: block;
  }  
}