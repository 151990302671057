.finance4{
  table{
    width: 100%;
    border-collapse: collapse;
  }
  th{
    padding: 8px 0;
  }
  td{
    padding: 11px 0;
  }
  th,td{
    text-align: center;
    border-right: 1px solid #bbd4e8;
    border-bottom: 1px solid #bbd4e8;
  }
  tr:last-child{
    td{
      border-bottom: 0;
    }
  }
  tr{
    td:last-child{
      border-right: 0;
    }
    th:last-child{
      border-right: 0;
    }
  }
  .ltitle{
    font-weight: bold;
  }
  .fw4{
    display: inline-block;
    width: 43px;

    
    ul{
      margin-bottom: 4px;
      li{
        border: 1px solid #d9d9d9;
        height: 10px;
        margin: -1px 0;
        &:nth-child(1){
          background-color: #78B1FF;
        }
        &:nth-child(2){
          background-color: #A3CBFF;
        }
        &:nth-child(3){
          background-color: #C4DDFF;
        }
        &:nth-child(4){
          background-color: #DEECFF;
        }
        &.nobg{
          background-color: #fff;
        }
      }
    }


    .bar{
      position: relative;
      z-index: 1;     
      &:hover{
        .tip{
          display: block;
        }
      }       
    }

  }
  .fw4tips{
    position: relative;
    z-index: 1;
    display: inline-block;
    width: 12px;
    height: 12px;
    background: url(../img/question.png) no-repeat 0 0;
    margin-left: 3px;
    &:hover{
      .tip{
        display: block;
        left: -200px;
        top: 40px;
      }
    }
  }
  .tip{
    font-weight: normal;
    position: absolute;
    top: 70px;
    left: -200px;
    display: none;
    width: 400px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    text-align: left;
    color: #315b6c;
    padding: 10px;
    line-height: 165%;
    box-shadow: rgba(0,0,0,0.5) 3px 3px 5px;
    .red{
      color: #f00;
    }

  }  
  .fw4tr{
    td:nth-child(10){
      .tip{
        left: -290px;
      }
    }
    td:nth-child(11){
      .tip{
        left: -370px;
      }
    }    
  }
    .nodatatip{
      padding-right: 14px;
      display: inline-block;
      width: 12px;
      height: 12px;
      background: url(../img/question.png) no-repeat right 0;      
    }  
}

.afinance4{
  // border: 1px solid #bbd4e8;
  border-top: 1px solid #ccc;
  padding-top: 13px;
  table{
    border: 1px solid #bbd4e8;
  }
  margin-top: 13px;
  .hypj_hy{
    margin-bottom: 4px;
  }
  .hypj_hy2{
    color: #999;
  }

    td{
      padding: 13px 0;
    }
  
}