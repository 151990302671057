.quotecore{
  padding: 3px 0;
  table{
    width: 100%;
    border-collapse: collapse;
    td{
      padding: 5px 3px;
    }
  }
  .help{
    background: url(./help.png) no-repeat;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-left: 2px;
    vertical-align: -1px;
  }
}