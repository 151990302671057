.headquotebox{
  margin-top: 5px;
  border: 1px solid #8EAEC8;
  .title{
    width: 84px;
    height: 31px;
    border-right: 1px solid #8EAEC8;
    float: left;
    font-weight: bold;
    text-align: center;
    line-height: 31px;
  }
  .hqb_qqgsd{
    // padding-top: 9px;
    float: left;
    width: 910px;
    overflow: hidden;
  }
}

.hqb_link2{
  li{
    float: left;
  }
}
.hqb_t{
  height: 31px;
  background: url(./bg.png) repeat-x;
  // a:link, a:visited{
  //   color: #fff;
  // }
  // a:hover{
  //   color: #ea5404;
  // }
  .title{
    a:link, a:visited{
      color: #fff;
    }
    a:hover{
      color: #ea5404;
    }    
  }
  .hqb_links{
    ul{
      padding-top: 8px;
      padding-left: 5px;
      float: left;
    }
    li{
      float: left;
      color: #a0a0a0;
      margin: 0 2px;
      a{
        color: #fff;
        &:hover{
          color: #ff5025;
        }
      }
    }
  }
  .hqb_t_search{
    float: right;
    margin-top: 3px;
    margin-right: 3px;
    #hqb_t_search_input{
      height: 19px;
      line-height: 19px;
      width: 203px;
      padding-right: 25px;
      text-indent: 5px;
      color: #666;
      border: solid 1px #ff5025;
    }
    .hqb_t_search_submit{
      border: none;
      background-color: #ff5025;
      line-height: 21px;
      width: 51px;
      color: #fff;
      cursor: pointer;
      &:hover{
        filter: brightness(1.2);
      }
    }
  }

}
.hqb_qqgs{
  clear: both;
  height: 31px;
  border-bottom: 1px solid #8EAEC8;
  overflow: hidden;
  .title a{
    color: #3F3F3F;
    &:hover{
      color: initial;
    }
  }
}

.hqb_sjzx{
  height: 31px;
  border-bottom: 1px solid #8EAEC8;
  .title a{
    color: #3F3F3F;
  }
  .hqb_sjzx_d{
    padding-top: 8px;
    a{
      margin-left: 7px;
    }
    .red{
      color: #f00;
    }
  }
}

.hqb_hsgt{
  height: 31px;
  .title a{
    color: #3F3F3F;
  }
  &:after{
    height: 0;
    content: '\20';
    clear: both;
    display: block;
  }  
}


.hqb_center_icon{
  display: inline-block;
  background: url(./hqb_center_icon.png) no-repeat;
  width: 15px;
  height: 14px;
  vertical-align: -3px;
  margin-right: 3px;
}

.hqb_qqgs_icon{
  display: inline-block;
  background: url(./hqb_qqgs_icon.png) no-repeat;
  width: 14px;
  height: 14px;
  vertical-align: -3px;
  margin-right: 3px;
}

.hqb_data_icon{
  display: inline-block;
  background: url(./data.png) no-repeat;
  width: 14px;
  height: 14px;
  vertical-align: -3px;
  margin-right: 3px;
}

.hqb_hsgt_icon{
  display: inline-block;
  background: url(./hsgt.png) no-repeat;
  width: 14px;
  height: 14px;
  vertical-align: -3px;
  margin-right: 3px;
}

.hqb_hsgt_d{
  float: left;
  width: 912px;
  padding-top: 8px;
}