#embacktop {
  width: 18px;
  position: fixed;
  bottom: 250px;
  left: 50%;
  text-align: center;
  _position: absolute;
  z-index: 9999;
  // display: none;
  _position: absolute;
}

#embacktop #backtophq,
#embacktop #backtopyj,
#embacktop #gotop {
  display: block;
  width: 50px;
  height: 51px;
  background: url(./20160728143011.png) no-repeat;
  font-size: 12px;
}

#embacktop #backtophq {
  background-position: 0 0;
  color: #fff;
  text-decoration: none;
  padding-top: 35px;
  height: 16px;
}

#embacktop #backtophq:hover {
  background-position: -50px 0;
}

#embacktop #backtophq.on {
  background-position: -102px -2px;
  background-color: #3A5E95;
}

#embacktop #backtopyj {
  background-position: 0 -115px;
  color: #fff;
  text-decoration: none;
  height: 51px;
  margin-top: 4px;
}

#embacktop #backtopyj:hover {
  background-position: -60px -115px;
}

#embacktop #gotop {
  background-position: 0 -50px;
  margin-top: 4px;
  display: block;
}

#embacktop #gotop:hover {
  background-position: -50px -50px;
}

#embacktop #backtopsearch {
  position: absolute;
  left: -237px;
  top: 0;
  background-color: #3A5E95;
  height: 36px;
  width: 238px;
  display: none;
  padding-top: 15px;
}

#embacktop #backtopsearch form {
  margin: 0;
  padding: 0;
  display: inline;
  position: relative;
}

#embacktop #backtopsearch input {
  width: 123px;
  padding: 4px;
  font-size: 12px;
  font-family: simsun;
  border: 0;
  height: 16px;
  vertical-align: middle;
}

#embacktop #backtopsearch #backtopsearchsbm {
  border-style: none;
  border-color: inherit;
  border-width: 0;
  width: 60px;
  color: #315895;
  height: 24px;
  background: url(./20160728143011.png) 5px -174px no-repeat #BBD4E8;
  text-align: right;
  padding-right: 7px;
  vertical-align: middle;
  cursor: pointer;
}