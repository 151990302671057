.zjl_charts{
  margin-top: 10px;
  .lbt{
    width: 330px;
    float: left;
  }
  .rxt{
    width: 660px;
    float: right;
  }
}

.zjl_bt{
  height: 370px;  
}

.zjl_bt_nums{
  ul{
    float: left;
    margin: 0 0 10px 0;
    li{
      float: left;
      width: 150px;
      padding-left: 10px;
      margin:4px 0;
    }
    .lic{
      text-align: center;
    }
  }
  .tl{
    display: inline-block;
    width: 20px;
    height: 10px;
    margin-right: 3px;
    vertical-align: middle;
  }
  .cdd_lr{
    background-color: #650000;
  }
  .cdd_lc{
    background-color: #004800;
  }
  .dd_lr{
    background-color: #AE0000;
  }
  .dd_lc{
    background-color: #096E09;
  }
  .zd_lr{
    background-color: #F83434;
  }
  .zd_lc{
    background-color: #1DA71F;
  }
  .xd_lr{
    background-color: #FF8081;
  }
  .xd_lc{
    background-color: #45C741;
  }
}

.zjl_ss{
  height: 370px;
}

.zjl_ss_tl{
  padding: 10px 0 10px 0px;
  .tl{
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-left: 30px;
  }
  .zl{
    background-color: #FE3EE1;
  }
  .cdd{
    background-color: #650000;
  }
  .dd{
    background-color: #FF1117;
  }
  .zd{
    background-color: #FFB83D;
  }
  .xd{
    background-color: #94C4EE;
  }
}

.zjl_ss_nums{
  padding: 5px 0 0px 0;
  table{
    width: 100%;
    border-collapse: collapse;
    td{
      padding: 0 6px;
      height: 25px;
    }
    .v{
      text-align: right;
    }
  }
}

.zjl_ph{
  height: 370px;
}

.zjl_ph{
  .zjl_ss_tl{
    margin-top: 40px;
  }
}

#zjlbchart{
  height: 250px;
}
#pq_zjl_chart{
  height: 240px;
}

.astockzjltab{
  margin-top: 10px;
  border-bottom: 4px solid #2f5895;
  &>ul{
    li{
      border-top: 1px solid #bbd4e8;
      border-right: 1px solid #bbd4e8;
      float: left;
      // margin-right: -1px;
      a{
        display: block;
        font-size: 16px;
        padding: 0 20px;
        font-weight: bold;
        height: 28px;
        line-height: 28px;
        color: #484848;
        &:hover{
          color: #f40;
        }
      }
      &.active{
        border: 1px solid #2f5895;
        a{
          
          border-bottom: 5px solid #fff;
          margin-bottom: -5px;
        }
      }
    }
  }
  .tg{
    margin-left: 50px;
    margin-top: 7px;
    float: left;
    a{
      color: #ff4901;
      background: url(../img/tgi.png) no-repeat;
      padding-left: 18px;
    }
  }
}