.helpicon{
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: -2px;
  background: url(../img/question.png) no-repeat;
  cursor: help;
  position: relative;
  &:hover{
    .tiptxt{
      display: block;
    }
  }
  .tiptxt{
    position: absolute;
    display: none;
    top: 20px;
    background-color: #fff;
    line-height: 165%;
    padding: 8px 8px 4px 8px;
    border: 1px solid #ccc;
    box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 3px;
  }
}