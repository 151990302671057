.sider_favor_img{
  width: 100%;
  border-collapse: collapse;
  .img{
    width: 75px;

    img{
      width: 75px;
      height: 36px;
    }
  }
  tr{
    td{
      border-bottom: 1px solid #E6E6E6;
      padding: 0;
      height: 40px;
      &:first-child{
        padding-left: 3px;
      }

    }
    &:last-child{
      td{
        border-bottom: 0;
      }
    }
  }
 
}
  .sider_favor_img_bmore{
    padding: 11px 10px;
    text-align: right;
  } 

.sider_history_img{
  tr{
    td{
      padding: 0;
      height: 46px;
      img{
        width: 75px;
        height: 36px;
      }
    }
  }
}