.profitchart{
  position: relative;
  border: 1px solid #BBD4E8;
  margin-top: -1px;
  .t{
    font-weight: bold;
    padding: 10px 12px;
    a{
      color: #3f3f3f;
    }
  }
  .more{
    position: absolute;
    right: 12px;
    top: 10px;
  }
  .chart{
    padding: 3px 0;
  }
}