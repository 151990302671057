@import '../../shared/less/variable.less';

.zsquote3l{
  .quote3l_l{
    width: 200px;
    float: left;
  }
  .quote3l_c{
    width: 630px;
    float: left;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }
  .quote3l_r{
    width: 160px;
    float: right;
    position: relative;

  }
  &::after{
    height: 0;
    content: '\20';
    clear: both;
    display: block;
  }  
}

.zs_brief{
  margin-top: 7px;
  // border-bottom: 1px solid #BBD4E8;

  .quote_quotenums{
    .zxj{
      font-size: 30px;
      font-family: @yhfont;
      font-weight: bold;
      text-align: center;
      margin-top: 1px;
      .price_up_icon,.price_down_icon{
        margin-left: 4px;
      }
    }
    .zd{
      text-align: center;
      font-size: 17px;
      font-family: @yhfont;
      margin-top: 3px;
      // margin-bottom: 9px;
      &>span{
        margin: 0 6px;
      }
    }
    .errortp_zhj{
      font-size: 30px;
      font-family: @yhfont;
      font-weight: bold;
      text-align: center;
      // margin-top: 6px;      
    }
    .errorstatetp{
      // color: #f00;
      text-align: center;
      font-size: 18px;
      padding-top: 0px;
      font-family: 'microsoft yahei';
    } 
    .errorstate{
      color: #f00;
      text-align: center;
      font-size: 22px;
      padding-top: 23px;
      font-family: 'microsoft yahei';
    }
  }

  .uk_blinks{
    margin: 0px 0 0 0 ;
    padding: 0px 0 5px 18px;
    a{
      display: block;
      width: 95px;
      height: 50px;
      font-family: 'microsoft yahei';
      font-size: 16px;
      padding-left: 85px;
      padding-top: 5px;
      &:hover{
        padding-left: 90px;
        width: 90px;
      }
      span{
        font-family: simsun;
        font-weight: bold;
      }
    }

    
  }
  .brief_info_c{
    padding: 2px 2px 0 2px;
    table{
      width: 100%;
      border-collapse: collapse;
      td{
        padding: 0;
        height: 32px;
        .title_help{
          margin-left: 0;
        }
      }
      .n{
        text-align: right;
      }
      .title_help{
        vertical-align: -1px;
        position: relative;
        z-index: 11;
        &:hover{
          .ssy{
            display: block;
          }
        }
        .ssy{
          display: none;
          position: absolute;
          top: 20px;
          left: -165px;
          width: 340px;
          background-color: #fff;
          text-align: left;
          border: 1px solid #DCDCDC;
          padding: 5px 6px;
          box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 4px;
          ul{
            li{
              list-style: none;
              margin: 3px 0;
              line-height: 170%;
              padding-left: 13px;
            }
          }
          .ssy_t{
            font-weight: bold;
            padding-left: 0;
          }
        }
      }
    }

  }

  .zs_rquote{
    text-align: center;
    line-height: 220%;
    padding-top: 10px;
    .hq{
      font-size: 14px;
      font-weight: bold;
      a{
        color: #0067cf;
        &:hover{
          color: #ea5404;
        }
      }
    }
    .nums{
      font-family: Arial;
    }
  }

  .rlinks{
    // padding-top: 5px;
    a{
      display: inline-block;
      margin-top: 7px;
      padding-left: 17px;
    }
    .link1{
      background: url(../../shared/img/cny.png) no-repeat 3px 0;
    }
    .link2{
      background: url(../../shared/img/zx.png) no-repeat 0 0;
    }
    .link3{
      background: url(../../shared/img/n3.png) no-repeat 0 0;
    }
  }

  .rblocks{
    &>div{
      padding: 4px 0;
    }
    text-align: center;
    .t{
      font-weight: bold;
      font-size: 13px;
    }
  }

  .rblocks4{
    &>div{
      padding: 1px 0;
    }
    text-align: center;
    .t{
      font-weight: bold;
      margin: 1px 0;
    }
    .price>span{
      margin: 0 3px;
    }
  }  

}

.zs_dkdc{
  height: 27px;
  background-color: #F3F3F3;

}

.qiquan_cp{
  line-height: 150%;
  padding-top: 5px;
  font-size: 13px;
  text-align: center;
  .price{
    font-family: 'Microsoft yahei';
    span:first-child{
      margin-right: 5px;
    }
  }
}