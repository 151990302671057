.quote_title_qr{
  position: relative;
  display: inline-block;
  z-index: 13;
  width: 24px;
  height: 24px;
  margin: 0 10px 0 10px;
  background: url(./phone-ewm.png) no-repeat;
  vertical-align: middle;
  line-height: normal;
  
  &:hover{
    .qrdivc{
      display: block;
    }
  }

  .qrdivc{
    display: none;
    position: absolute;    
    left: -408px;
    top: 15px;    
  }

  .qrdiv{
    margin-top: 20px;
    border: 1px solid #ccc;
    width: 440px;
    height: 150px;

    z-index: 1;
    background-color: #fff;
    

    &:after, &:before {
      position: absolute;
      bottom: 151px;
      left: 420px;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      pointer-events: none;
    }
    &:after {
      border-color: transparent;
      border-bottom-color: #fff;
      border-width: 6px;
      margin-left: -6px;
    }
    &:before {
        border-color: transparent;
        border-bottom-color: #bdc3c6;
        border-width: 7px;
        margin-left: -7px;
    }

    .t{
      font-weight: bold;
      margin: 5px 0 10px 0;
    }
  }
  .qrdivl{
    width: 230px;
    height: 130px;
    border-right: 1px solid #E5E5E5;
    margin-top: 10px;
    float: left;
  }
  .qrdivr{
    float: right;
    width: 205px;
    height: 130px;
    margin-top: 10px;
  }
  .qrdiv2l{
    float: left;
    text-align: center;
    width: 120px;
    img{
      margin: 0 auot;
    }
  }
  .qrdiv2r{
    float: left;
    padding-top: 30px;
    line-height: 20px;
  }


}

.quote_title_qr_b{
  position: relative;
  display: inline-block;
  z-index: 10;
  background: url(./mobile.png) no-repeat;
  height: 27px;
  margin-left: 20px;
  padding-left: 18px;
  vertical-align: middle;
  .t{
    // width: 28px;
  }
  .qrdivc{
    display: none;
    position: absolute;    
    left: -12px;
    top: 15px;    
  }
    
  &:hover{
    .qrdivc{
      display: block;
    }
  }
  .qrdiv{
    margin-top: 20px;
    border: 1px solid #ccc;

    z-index: 1;
    background-color: #fff;
    text-align: center;
    padding: 5px;
    line-height: 150%;
    

    &:after, &:before {
      position: absolute;
      bottom: 151px;
      left: 20px;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      pointer-events: none;
    }
    &:after {
      border-color: transparent;
      border-bottom-color: #fff;
      border-width: 6px;
      margin-left: -6px;
    }
    &:before {
        border-color: transparent;
        border-bottom-color: #bdc3c6;
        border-width: 7px;
        margin-left: -7px;
    }

    .t{
      font-weight: bold;
      margin: 5px 0 10px 0;
    }
  }
}