.quote_newx_box{
  .qnb_t{
    position: relative;
    border-bottom: 2px solid #2f5895;
    .qnb_t_tab{
      position: relative;
      bottom: -2px;
      li{
        font-size: 14px;
        
        color: #333;
        border: 1px solid #bbd4e8;
        border-left:none;
        border-bottom: none;
        padding: 5px 15px;
        float: left;
        a:link,a:visited{
          display: block;
          color: #333;
        }
        a:hover{
          color: #ea5404;
        }
        &.active{
          border: 1px solid #2f5895;
          border-bottom: 2px solid #fff;
          font-weight: bold;
        }
      }
    }
    .qnb_t_a{
      font-size: 14px;
      font-weight: bold;
      padding: 5px;
      a:link,a:visited{
        color: #333;
      }
      a:hover{
        color: #ea5404;
      }      
    }
    .more{
      position: absolute;
      right: 0;
      top: 5px;
    }
  } 

  .qnb_none{
    height: 170px;
    text-align: center;
    line-height: 170px; 
  }
}

.qnb_list{
  position: relative;
  // border: 1px solid #bbd4e8;
  border-top: none;
  ul{
    padding: 10px 0;
    li{
      padding-left: 18px;
      font-size: 14px;
      height: 30px;
      line-height: 30px;
      background: url(./dot.png) no-repeat 10px 14px;
      // word-break: break-all;
      a{
        max-width: 410px;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 30px;
        display: inline-block;
        white-space:nowrap;
        &.short{
          max-width: 320px;
        }
      }
      .time{
        font-size: 12px;
        float: right;
        margin-right: 8px;
      }
      &.nodot{
        background: none;
      }
    }

  }
  .nodata{
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    text-align: center;
  }
}