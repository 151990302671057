.fullscreenbtn{
  font-size: 14px;
  background: url(./fullscreen.gif) no-repeat 0 -2px;
  padding-left: 20px;
  display: inline-block;
  height: 20px;
  // vertical-align: -1px;
}

.fullscreen_chart{

  .fsc_mask{
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9001;
  }
  .fsc_iframe{
    position: fixed;
    left: 0;
    top: 38px;
    width: 100%;
    height: 100%;
    z-index: 9002;
    iframe{
      width: 99%;
      height: 95%;
      background-color: #fff;
      margin: 0 auto;
      display: block;
    }  
  }
  .fsc_close{
    font-size: 20px;
    line-height: 25px;
    height: 25px;
    width: 25px;
    text-align: center;
    cursor: pointer;
    position: fixed;
    top: 34px;
    right: 4px;
    z-index: 9003;
    color: #fff;
    background: red;
    border-radius: 50%;
    font-weight: bold;
  }
}