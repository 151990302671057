.siderstockcalendarcontent{
  height: 292px;
  overflow: auto;
  .nodata{
    padding-top: 130px;
    text-align: center;
  }
  table{
    border-collapse: collapse;
    margin: 10px 0;
    td,th{
      padding: 0;
    }
    .time{
      width: 45px;
      text-align: center;
      vertical-align: top;
      padding-top: 5px;
      border-right: 1px solid #dcdcdc;
    }
    .year{
      color: #888;
    }
    .event{
      padding-left: 10px;
      padding-top: 4px;
      padding-bottom: 8px;
      line-height: 160%;
      position: relative;
      .circle{
        position: absolute;
        left: -4px;
        top: 8px;
        width: 8px;
        height: 8px;
        background-color: #B5B5B5;
        border-radius: 50%;
      }
      .redcircle{
        background-color: #ea5404;
      }
    }
    .title{
      color: #666;
    }
  }
}