.sdltgd{
  border: 1px solid #BBD4E8;
  border-top: 0;
  position: relative;
  height: 140px;
  .t{
    font-weight: bold;
    padding: 10px;
  }
  .more{
    position: absolute;
    right: 10px;
    top: 10px;    
  }
  table{
    width: 100%;
    border-collapse: collapse;
    th,td{
      padding: 3px 3px;
    }
    .tac{
      text-align: center;
    }
  }
}