.mainquotecharts{
  position: relative;
  .mqc_tab{
    // float: left;
    display: inline-block;
    border-bottom: 1px solid #2f5895;
    border-right: 1px solid #2f5895;    
    li{
      display: inline-block;
      a{
        display: block;
        position: relative;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        padding: 0 5px;
        min-width: 30px;
        text-align: center;
        border-left: 1px solid #2f5895;
        border-top: 1px solid #2f5895;
        color: #494949;
        &:hover{
          color: #ea5404;
        }
        &.active{
          background-color: #2f5895;
          color: #fff;
            &:after {
              position: absolute;
              top: 100%;
              left: 50%;
              border: 5px solid transparent;
              content: "";
              height: 0;
              width: 0;
              pointer-events: none;
              border-top-color: #2f5895;
              margin-left: -5px;
            }

        }
      }
    }
  }

  .mqc_tab2{
    // float: left;
    // display: inline-block;
    // border-bottom: 1px solid #2f5895;
    // border-right: 1px solid #2f5895;  
    ul{
      display: inline-block;
      border-bottom: 1px solid #2f5895;
      border-right: 1px solid #2f5895; 
    }  
    li{
      display: inline-block;
      a{
        display: block;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        padding: 0 5px;
        border-left: 1px solid #2f5895;
        border-top: 1px solid #2f5895;
        &.active{
          background-color: #2f5895;
          color: #fff;
        }
      }
    }
  }  

  .mqc_time_header{
    position: relative;
    height: 35px;
    z-index: 1;
  }

  .typechoice{
    position: absolute;
    right: 0;
    top: 1px;
  }

  .mqc_k_header{
    position: relative;
  }

  .k_tab{
    margin-top: 10px;
  }

  .static_k{
    position: relative;
    margin-top: 14px;
  }

  .kzoom{
    position: absolute;
    right: 80px;
    top: 15px;
    a{
      background-color: #2f5895;
      color: #fff;
      padding: 5px 6px;
      margin-right: 3px;
      &:hover{
        filter: brightness(1.2);
      }
    }
  }
  .kzoomr{
    right: 10px;
  }

  .kfq{
    position: absolute;
    z-index: 12;
    right: 0px;
    top: 12px;
    cursor: pointer;
    .kfq_t{
      border: 1px solid #2f5895;
      width: 60px;
      height: 18px;
      line-height: 18px;
      padding-left: 5px;
      background: url(./xl.png) no-repeat 47px 0;
    }
    &:hover{
      ul{
        display: block;
      }
    }
    ul{
      border: 1px solid #2f5895;
      border-top: 1px solid #fff;
      margin-top: -1px;
      background-color: #fff;
      display: none;
      li{
        a{
          display: block;
          line-height: 20px;
          padding-left: 5px;
          color: #494949;
          &:hover{
            background-color: #e7e7e7;
          }          
        }

      }
    }

  }

  .qsc_k_zb2{
    margin-left: 55px;
    // margin-top: -3px;
  }
  .qsc_k_zb2 li{
    float: left;
    background: #545454;
    color: #fff;
    cursor: pointer;
    width: 41px;
    height: 20px;
    line-height: 20px;
    text-align: center;
  }
  .qsc_k_zb2 li.active{
    background: #ccc;
    color: #494949;
  }

  .qsc_k_img{
    height: 365px;
  }

  .qsc_k_zb_b{
    position: absolute;
    left: 520px;
    top: 210px;
  }
  .qsc_k_zb_b ul{
    margin-top: 3px;
  }
  .qsc_k_zb_b li{
    margin: 0px 0;
    padding-left: 13px;
    background: url(./check.png) no-repeat 0 2px;
    cursor: pointer;
  }
  .qsc_k_zb_b li.active{
    background: url(./checked.png) no-repeat 0 2px;
  }
  .qsc_k_zb_b li:hover{
    color: #000;
  }

  .qsc_k_zb1{
    position: absolute;
    left: 520px;
    top: 15px;
  }
  .qsc_k_zb1 ul{
    margin-top: 3px;
  }
  .qsc_k_zb1 li{
    margin: 1px 0;
    padding-left: 13px;
    background: url(./check.png) no-repeat 0 2px;
    cursor: pointer;
  }
  .qsc_k_zb1 li.active{
    background: url(./checked.png) no-repeat 0 2px;
  }
  .qsc_k_zb1 li:hover{
    color: #000;
  }


  .kchart_d{
    margin-top: 13px;
  }
  .kchart_d_fs{
    cursor: pointer;
  }


  .mqc_fullscreen{
    display: inline-block;
    margin-left: 5px;
    vertical-align: -2px;
  }
  .mqc_fullscreen_down{
    position: absolute;
    right: 10px;
    top: 30px;
    width: 50px;
  }
  .mqc_fullscreen2{
    position: absolute;
    right: 10px;
    top: 40px;
    z-index: 11;
  }
  .mqc_fullscreen3{
    position: absolute;
    right: 10px;
    top: 15px;
    z-index: 11;
  }

  .time_static_img{
    // position: relative;

  }
  .time_chart{
    height: 276px;
    position: relative;
    .picrfixad img{
      position: absolute;
      right: 10px;
      top: 195px;
    }      
  }

  .k_chart{
    height: 385px;
    position: relative;
    z-index: 0;
  }

  .concept_tg_img{
    position: absolute;
    top: 181px;
    right: 5px;
    z-index: 1;
  }

  .concept_tg_img_k{
    position: absolute;
    top: 113px;
    right: 20px;
    z-index: 1;
  }  

  .timessechart{
    z-index: 0;
  }

}

.mcharts_txtg{
  border: 1px solid #2f5895;
  color: #ea5404;
  font-weight: bold;
  height: 19px;
  line-height: 19px;
  margin-left: 10px;
  display: inline-block;
  vertical-align: 1px;
  padding: 0 5px;
  em{
    display: inline-block;
    width: 12px;
    height: 12px;
    background: url(booktip.png) no-repeat;
    vertical-align: -2px;
    margin-right: 1px;
  }
  &:hover{
    background-color: #f5f5f5;
  }
}

.cmfb_img{
  .t{
    margin-bottom: 2px;
    cursor: pointer;
    &:hover{
      filter: brightness(0.8);
    }
    em{
      position: relative;
      display: inline-block;
      vertical-align: -2px;
      width: 12px;
      height: 12px;
      border: 1px solid #dddddd;
      &:after{
        content: "";
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border: 3px solid rgba(0, 0, 0, 0);
        border-left: 3px solid #666666;
        border-bottom: 3px solid #666666;
        transform: rotate(-45deg);
        left: 3px;
        top: 1px;
      }
    }
  }
  a{
    height: 155px;    
  }
  width: 60px;
  position: absolute;

  right: -5px;
  top: 30px;
  background-color: #fff;
  z-index: 10;
}