.stocksiderdc{
  height: 150px;
  form{
    .fitem{
      padding: 10px 0 5px 15px;
    }
    .btn{
      padding-left: 0;
      text-align: center;
      input{
        background-color: #2f5895;
        border: 0;
        color: #fff;
        padding: 4px 14px;
        cursor: pointer;
        &:hover{
          filter: brightness(1.2);
        }
      }
    }
    .kzkd{
      vertical-align: -2px;
    }
  }
  .percentbar{
    display: inline-block;
    height: 12px;
    min-width: 5px;
    vertical-align: -2px;
    margin-left: 10px;
    margin-right: 5px;
  }
  .time{
    margin-top: 15px;
    text-align: center;
  }
  .hasvote{
    color: #999;
    background-color: #E4E4E4;
    width: 50px;
    padding: 3px 0;
    text-align: center;
    margin: 0 auto;
    cursor: default;
  }
}