.reporttable{
  width: 98%;
  margin: 0px auto;
  font-size: 14px;
  border-collapse: collapse;
  th{
    height: 23px;
    font-weight: normal;
  }
  td{
    height: 27px;
  }
  .time{
    font-size: 12px;
  }
  .title{
    span{
      display: inline-block;
      vertical-align: -4px;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space:nowrap;
    }
  }
  .rating{
    text-align: center;
    font-weight: bold;
  }
  .tal{
    text-align: left;
  }
  .orgname{
    span{
      display: inline-block;
      vertical-align: -4px;
      max-width: 70px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space:nowrap;
    }
  }  
}