.quotedataimg{
  margin-top: 10px;
  background: url(./bg.gif) no-repeat;
  width: 200px;
  text-indent: 10px;
  height: 40px;
  font-weight: bolder;
  font-size: 15px;
  color: #fff;
  font-family: "黑体";
  line-height: 40px;
  &:hover{
    filter: brightness(1.1);
  }
}