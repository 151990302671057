.select{
  display: inline-block;
  // width: 200px;
  position: relative;
  z-index: 2;
  cursor: pointer;
  text-align: left;
  &:hover{
    .drop{
      display: block;
    }
  }
  .select_n{
    border: 1px solid #2f5895;
    background-color: #fff;
    padding: 0 30px 0 0;
    position: relative;
    height: 18px;
    
    .t{
      padding: 0px 6px;
      line-height: 18px;
    }
    .select_arrow{
      background-color: #2F5895;
      width: 18px;
      height: 18px;
      display: block;
      position: absolute;
      right: 0;
      top: 0;        
      &:after{
        position: absolute;
        left: 5px;
        top: 6px;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        pointer-events: none;
        border-color: rgba(83, 213, 83, 0);
        border-top-color: #fff;
        border-width: 5px;
      }

    }
  }
  .drop{
    display: none;
    position: absolute;
    top: 18px;
    background-color: #fff;
    width: 100%;
    ul{
      border: 1px solid #2f5895;
      border-top: none;  
      padding: 3px 0; 
      li{
        line-height: 21px;
        padding-left: 6px;
        &:hover{
          background-color: #e7e7e7;
        }
      }     
    }
  }
}

.select_hide{
  &:hover{
    .drop{
      display: none;
    }
  }
}