@import './less/variable.less';

body, select{
  font-size: 12px;
  color: #494949;
  font-family: @basefont;
  margin: 0;
  padding: 0;
}



img{
  border:none;
}

a{
  color: #003497;
  text-decoration: none;
  border: none;
}

a:hover{
  color: #ea5404;
}

input{
  outline: none;
  font-family: simsun;
  font-size: 12px;
}

.orange{
  color: #ea5404;
}

.price_up{
  color: #f00;
}

.price_down{
  color: #090;
}

.price_up_bg{
  background-color: #f00;
}

.price_draw_bg{
  background-color: #B9B9B9;
}


.price_down_bg{
  background-color: #090;
}

.price_up2{
  color: #FF00FF;
}

.price_down2{
  color: #14C3DC;
}

.price_up_icon{
  display: inline-block;
  width: 17px;
  height: 23px;
  background: url(./img/up.png) no-repeat;
}

.price_down_icon{
  display: inline-block;
  width: 17px;
  height: 23px;
  background: url(./img/down.png) no-repeat;
}

.color_reverse{
  .price_up{
    color: #090;
  }
  .price_down{
    color: #f00;
  }
  .price_up_icon, .price_down_icon{
    display: none;
  }

  .blinkgreen {
      animation-name: blinkred;
      animation-duration: 500ms;
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
  }
  .blinkred {
    animation-name: blinkgreen;
    animation-duration: 500ms;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
  }
  
}

.container{
  width: 1000px;
  margin: 0 auto;
}

ul{
  padding: 0;
  margin: 0;
  list-style: none;
}

.clear_fix{
  display: block;
  height: 0;
  clear: both;
}

.self_clearfix{
  &::after{
    height: 0;
    content: '\20';
    clear: both;
    display: block;
  }
}

.lr{
  .left{
    float: left;
  }
  .right{
    float: right;
  }
  &::after{
    height: 0;
    content: '\20';
    clear: both;
    display: block;
  }
}


.quote2l{
  //两列 平分
  .quote2l_l{
    width: 490px;
    float: left;
  }
  .quote2l_r{
    width: 490px;
    float: right;
  }

  //两列 主-侧
  .quote2l_ml{
    width: 790px;
    float: left;
  }
  .quote2l_cr{
    width: 200px;
    float: right;

  }

  //两列 侧-主
  .quote2l_cl2{
    width: 200px;
    float: left;
  }
  .quote2l_cr2{
    width: 790px;
    float: right;
    //主侧中间
    .quote2l_cr2_m{
      width: 580px;
      float: left;      
    }
    //主侧侧边
    .quote2l_cr2_c{
      width: 200px;
      float: right;      
    }
    //主侧均分
    .quote2l_cr2_j{
      clear: both;
      .quote2l_cr2_jl{
        width: 390px;
        float: left;
      }
      .quote2l_cr2_jr{
        width: 390px;
        float: right;
      }      
    }
    
  }  

  &::after{
    height: 0;
    content: '\20';
    clear: both;
    display: block;
  }  
}

//三列 侧-主-侧
.quote3l{
  .quote3l_l{
    width: 200px;
    float: left;
  }
  .quote3l_c{
    width: 580px;
    float: left;
    margin-left: 10px;
  }
  .quote3l_r{
    width: 200px;
    float: right;
  }
  &::after{
    height: 0;
    content: '\20';
    clear: both;
    display: block;
  }  
}

//三列 侧-主-主
.quote3l2{
  .quote3l2_l{
    width: 200px;
    float: left;
  }
  .quote3l2_c{
    width: 390px;
    float: left;
    margin-left: 10px;
  }
  .quote3l2_r{
    width: 390px;
    float: right;
  }
  &::after{
    height: 0;
    content: '\20';
    clear: both;
    display: block;
  }  
}

//三角箭头
.arrow_left_top {
  &:after, &:before {
      position: absolute;
      bottom: 100%;
      left: 15%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      pointer-events: none;
  }

  &:after {
      border-color: transparent;
      border-bottom-color: #fff;
      border-width: 6px;
      margin-left: -6px;
  }
  &:before {
      border-color: transparent;
      border-bottom-color: #bdc3c6;
      border-width: 7px;
      margin-left: -7px;
  }
}

.arrow_center_top {
  &:after, &:before {
      position: absolute;
      bottom: 100%;
      left: 49%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      pointer-events: none;
  }

  &:after {
      border-color: transparent;
      border-bottom-color: #fff;
      border-width: 6px;
      margin-left: -6px;
  }
  &:before {
      border-color: transparent;
      border-bottom-color: #bdc3c6;
      border-width: 7px;
      margin-left: -7px;
  }
}


// //侧边栏表格
// .c_table{
//   .c_table_t{
//     font-size: 14px;
//     font-weight: bold;
//     color: #3f3f3f;
//     padding:5px 0px 5px 5px;
//     position: relative;
//     a{
//       color: #3f3f3f;
//       &:hover{
//         color: #ea5404;
//       }
//     }
//     border-bottom: 2px solid #039;
//     .more{
//       position: absolute;
//       font-size: 12px;
//       font-weight: normal;
//       right: 3px;
//       color: #003497;
//     }
//     ul{
//       padding: 0;
//       margin: -5px 0 -5px -5px;
//       li{
//         // width: 50%;
//         position: relative;
//         bottom: -2px;
//         padding: 0;
//         margin: 0;
//         display: inline-block;
//         padding: 0 0px;
//         a{
//           line-height: 27px;
//           border: 1px solid #bbd4e8;    
//           border-bottom: none;
//           display: block;
//           text-align: center;
//           font-weight: normal;
//           padding: 0 10px;
//         }
//         &.active{
//           position: relative;
//           bottom: -2px;
//           line-height: 27px;

//           a{
//             border: 1px solid #2f5895;
//             border-bottom: 2px solid #fff;            
//             font-weight: bold;
//           }
//         }
//         &.df2{
//           width: 50%;
//         }     
//         &.df3{
//           width: 33.3%;
//         }     
//       }

//     }
//   }
//   .c_table_table_c{
//     border: 1px solid #bbd4e8;
//     border-top: none;
//   }
//   .c_table_b{
//     padding: 10px;
//     text-align: right;
//   }
//   .b_more{
//     text-align: right;
//     padding: 5px 10px 8px 0;
//   }
// }

.table1{
  width: 100%;
  border-collapse: collapse;
  th{
    font-weight: normal;
    height: 27px;
    padding: 0;
  }
  td{
    height: 27px;
    padding: 0;
    // line-height: 25px;
  }
  &.pl2{
    td,th{
      padding-left: 2px;
    }
  }    
  &.pl3{
    td,th{
      padding-left: 3px;
    }
  }
  &.pl5{
    td,th{
      padding-left: 5px;
    }
  }
  &.pl8{
    td,th{
      padding-left: 8px;
    }
  }
  .tdr{
    text-align: right;
  }
  .tdl{
    text-align: left;
  }
  .tdc{
    text-align: center;
  }
  &.bborder{
    border-bottom: 1px solid #bbd4e8;
  }
  &.lh_1{
    th{
      line-height: 28px;
    }
    td{
      height: 24px;
    }
  }
  &.lh_11{
    th{
      height: 22px;
    }
    td{
      height: 25px;
    }
  }
  &.lh_2{
    th{
      line-height: 27px;
    }
    td{
      height: 25px;
    }
  }
  &.lh_3{
    th{
      line-height: 27px;
    }
    td{
      height: 28px;
    }
  }
  &.lh_3_1{
    th{
      height: 22px;
    }
    td{
      height: 27px;
    }
  }  
  &.lh_4{
    th{
      line-height: 25px;
    }
    td{
      height: 25px;
    }
  }
  &.lh_5{
    th{
      line-height: 29px;
    }
    td{
      height: 26px;
    }
  }  
  &.lh_5_1{
    th{
      line-height: 30px;
    }
    td{
      height: 26px;
    }
  }   
  &.lh_6{
    th{
      line-height: 30px;
    }
    td{
      height: 28px;
    }
  }
  &.lh_6_1{
    th{
      height: 30px;
    }
    td{
      height: 28px;
    }
  }  
  &.lh_7{
    th{
      line-height: 28px;
    }
    td{
      height: 25px;
    }
  }
  &.lh_8{
    th{
      line-height: 24px;
    }
    td{
      height: 22px;
    }
  }
  &.lh_9{
    th{
      height: 21px;
    }
    td{
      height: 21px;
    }
  }  
  &.lh_10{
    th{
      height: 26px;
    }
    td{
      height: 26px;
    }
  } 
  .tdbmore{
    text-align: right;
    padding-right: 12px;

  }
}

.ofd_td_90{
  display: inline-block;
  vertical-align: -4px;
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space:nowrap;
}

.ofd_td_72{
  display: inline-block;
  vertical-align: -4px;
  max-width: 72px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space:nowrap;
}

.ofd_td_65{
  display: inline-block;
  vertical-align: -4px;
  max-width: 65px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space:nowrap;
}


.ofd_td_60{
  display: inline-block;
  vertical-align: -4px;
  max-width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space:nowrap;
}


.quote_title_name_220{
  display: inline-block;
  // vertical-align: -14px;
  vertical-align: middle;
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space:nowrap;
}

.quote_title_name_190{
  display: inline-block;
  // vertical-align: -14px;
  vertical-align: middle;
  max-width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space:nowrap;
}

.quote_title_name_130{
  display: inline-block;
  // vertical-align: -14px;
  vertical-align: middle;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space:nowrap;
}

@keyframes blinkred {
    0% {
        background-color: #FFDDDD;
        opacity:1;
    }
    22% {
        background-color: #FFEEEE;
    }
    77% {
        background-color: #FFDDDD;
    }
    100% {
        background-color: #FFEEEE;
    }
}
//红色闪烁
.blinkred {
  animation-name: blinkred;
  animation-duration: 500ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}


@keyframes blinkgreen {
    0% {
        background-color: #b4f7af;
        opacity:1;
    }
    22% {
        background-color: #ccffcc;
    }
    77% {
        background-color: #b4f7af;
    }
    100% {
        background-color: #ccffcc;
    }
}

//绿色闪烁
.blinkgreen {
    animation-name: blinkgreen;
    animation-duration: 500ms;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
}

@keyframes blinkblue {
    0% {
        background-color: #b2c0e0;
        opacity:1;
    }
    22% {
        background-color: #c7d0e6;
    }
    77% {
        background-color: #b1bedb;
    }
    100% {
        background-color: #d1daf0;
    }
}
//蓝色闪烁
.blinkblue {
    animation-name: blinkblue;
    animation-duration: 500ms;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
}

//两栏自适应宽度div
.l2cdiv{
  .l2cdivi{
    line-height: 27px;
    .l2cdivi_half{
      width: 50%;
      float: left;
    }
    .l2cdivi_l{
      float: left;
      padding-left: 2px;
    }
    .l2cdivi_r{
      float: right;
      padding-right: 2px;
    }
    .l2cdivi_z{
      padding-left: 2px;
    }
  }
  &::after{
    height: 0;
    content: '\20';
    clear: both;
    display: block;
  }
}

hr.hrblue{
  clear: both;
  height:1px;
  border:none;
  border-top:1px solid #BBD4E8;
}

.sider_mmp_t{
  border-bottom: 1px solid #bbd4e8;
  border-collapse: collapse;
  width: 100%;
  td{
    line-height: 26px;
    padding: 0 8px;
  }
  .tdl{
    width: 40px;
  }
  .tdr{
    text-align: right;
    padding-right: 15px;
  }
}

.sider_wc_t{
  border-bottom: 1px solid #bbd4e8;
  border-collapse: collapse;
  width: 100%;
  td{
    line-height: 26px;
    padding: 0 8px;
  }
}

.newscroll{
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track  {
    background-color: #ddd;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background-color: #bbbbbb;
    
    &:hover{
      background-color: #8c8e8f;
    }
  }
}

.mt10{
  margin-top: 10px;
}

.title_help{
  display: inline-block;
  background: url(./img/question.png) no-repeat 0 0;
  width: 12px;
  height: 12px;
  margin-left: 3px;
}