.luyangg{
  position: relative;
  .up{
    position: absolute;
    width: 10px;
    height: 10px;
    left: 475px;
    top: 4px;
    background: url(./up.png) no-repeat;
    &:hover{
      filter: brightness(0.7);
    }
  }

  .down{
    position: absolute;
    width: 10px;
    height: 10px;
    left: 475px;
    top: 15px;
    background: url(./down.png) no-repeat;
    &:hover{
      filter: brightness(0.7);
    }
  }
  .luyangg_title{
    position: absolute;
    top: 7px;
    left: 212px;
    width: 255px;
    ul{
      position: absolute;
      top: 0;
      li{
        height: 14px;
        overflow: hidden;
        width: 100%;
      }
    }    
  }
  
  .luyangg_title_a{
    position: absolute;
    top: 11px;
    left: 250px;
    width: 415px;
    ul{
      position: absolute;
      top: 0;
      li{
        height: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 415px;
        font-weight: bold;
      }
    }    
  }
}

.luyangg_a{
  a{
    color: #494949;
    &:hover{
      color: #f00;
    }
  }
}

.cfh_tg{
  width: 790px;
  height: 34px;
  position: relative;
  a{
    color: #ea5404;
  }
  .title{
    position: absolute;
    left: 250px;
    top: 10px;
  }
}