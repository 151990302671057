/*topnav*/
.head_links {
  padding: 1px;
  padding-left: 5px;
}
.head_links ul {
  background-repeat: repeat-x;
  line-height: 22px;
  padding-left: 0;
}
.head_links ul li {
  float: left;
  margin: 0 2px;
  color: #4B4B4B;
  text-align: center;
}
.head_links ul li.i {
  color: #ccc;
  width: 7px;
  text-align: center;
  overflow: hidden;
}
.head_links ul li a:link, .head_links ul li a:visited {
  color: #4B4B4B;
}
.head_links ul li a:hover {
  color: #ff4901;
}
.head_links ul li .red a:link, .head_links ul li .red a:visited {
  color: #ff4901;
}
