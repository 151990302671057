.topqqgs{
  // font-family: Arial, simsun;
  .topqqgs_btns{
    float: left;
    width: 50px;
    text-align: center;
    margin-top: 9px;
    .up{
      display: inline-block;
      width: 14px;
      height: 14px;
      background: url(./up.png) no-repeat;
      cursor: pointer;
      margin-left: 5px;
    }
    .down{
      margin-left: 3px;
      display: inline-block;
      width: 14px;
      height: 14px;
      background: url(./down.png) no-repeat;
      cursor: pointer;
    }
  }
  em{
    font-family: simsun;
    font-style: normal;
  }
  .nums{
    font-weight: bold;
    font-family: Arial;
  }
  .zdnums{
    text-decoration: underline;
    &:hover{
      color: initial;
    }
  }
  .jt{
    font-family: simsun;
    font-weight: normal;
  }
  .qqzs_shsz{
    line-height: 33px;
    // margin-top: 7px;
    // .zdnums{
    //   vertical-align: 0px;
    // }
    // margin-top: -2px;
  }
  .qqzs_gjzs{
    line-height: 32px;
    // margin-top: 9px;
    b{
      margin-right: 2px;
    }
    a{
      margin-left: 6px;
      &:first-child{
        margin-left: 0;
      }
    }
    .jt{
      margin-left: 3px;
    }
  }
  .topqqgs_links{
    float: left;
  }
}