.quote_add_zxg_btn:link, .quote_add_zxg_btn:visited{
  display: inline-block;
  border-radius: 12px;
  width: 70px;
  height: 24px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
  background: #ff4b19;
  padding: 0 5px;
}
.quote_add_zxg_btn:hover{
  background-color: #F33500;
}

.quote_delete_zxg_btn:link, .quote_delete_zxg_btn:visited{
  display: inline-block;
  border-radius: 12px;
  width: 70px;
  height: 24px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
  background: #999999;
  padding: 0 5px;
}
.quote_delete_zxg_btn:hover{
  background-color: #7A7A7A;
}
