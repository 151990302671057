.text_cut{
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space:nowrap;
}

.bardlinks{
  .text_cut{
    vertical-align: -2px;
  }
}

.zsbard{
  .text_cut{
    vertical-align: -2px;
  }  
}