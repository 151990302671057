.ggrlbar{
  height: 26px;
  border: 1px solid #bbd4e8;
  background-color: #f2f2f2;
  .t{
    float: left;
    width: 65px;
    height: 26px;
    text-align: center;
    border-right: 1px solid #bbd4e8;
    line-height: 26px;
    font-weight: bold;
    a{
      color: #0067cf;
      &:hover{
        color: #ea5404;
      }
    }
  }
  .ggrlbar_list{
    float: left;
    padding: 0px 0 0 0px;
    width: 470px;
    height: 26px;
    overflow: hidden;
    margin-left: 10px;
    ul{
      
      height: 26px;
      li{
        height: 26px;
        line-height: 26px;
        width: 470px;
        // width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space:nowrap;
      }
    }    
  }

  .upbtn{
    float: right;
    width: 30px;
    height: 22px;
    margin-top: 3px;
    background: url(./ggrl.jpg) no-repeat center 3px;
    cursor: pointer;
    &:hover{
      filter: brightness(0.9);
    }
  }
}