.sider_brief{
  border: 1px solid #BBD4E8;
  margin-top: -1px;
 table{
   border-collapse: collapse;
   width: 100%;
   td{
     padding-left: 8px;
   }
 } 
 .t1, .t2{
    td{
      height: 22px;
    }
  }

  .t2{
    border-top: 1px solid #BBD4E8;
  }

}