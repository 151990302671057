.sidertabbox{
  .sidertabbox_t{
    font-size: 14px;
    font-weight: bold;
    color: #3f3f3f;
    padding:5px 0px 5px 5px;
    position: relative;
    a{
      color: #3f3f3f;
      &:hover{
        color: #ea5404;
      }
    }
    i{
      font-style: normal;
      cursor: default;
    }
    border-bottom: 2px solid #039;
    .onet{
      padding: 1px 0;
    }
    .more{
      position: absolute;
      font-size: 12px;
      font-weight: normal;
      top: 8px;
      right: 3px;
      color: #003497;
      a{
        color: #003497;
        &:hover{
          color: #ea5404;
        }
      }
      i{
        color: #494949;
        font-style: normal;
      }
    }
    ul{
      padding: 0;
      margin: -5px 0 -5px -5px;
      height: 27px;
      li{
        // width: 50%;
        position: relative;
        bottom: -0px;
        padding: 0;
        margin: 0;
        display: inline-block;
        padding: 0 0px;
        a,i{
          line-height: 26px;
          border: 1px solid #bbd4e8;    
          border-bottom: none;
          border-left: none;
          display: block;
          text-align: center;
          font-weight: normal;
          padding: 0 10px;
          font-style: normal;
        }
        &:first-child{
          a,i{
            border-left: 1px solid #bbd4e8;    
          }
        }
        &.active{
          position: relative;
          // bottom: -2px;
          line-height: 27px;
          background-color: #fff;

          a,i{
            height: 28px;
            border: 1px solid #2f5895;
            border-bottom: 0;
            // border-bottom: 1px solid #fff;            
            font-weight: bold;
          }
        }
        &.df2{
          width: 50%;
        }     
        &.df3{
          width: 33.3%;
        }
        &.df4{
          width: 25%;
        }
        &.df5{
          width: 20%;
        }   
      }

    }

  }
  .sidertabbox_tsplit{
    padding: 5px 0px 5px 0px;
    ul{
      margin: -5px 0 -5px 0px;
      li{
        a,i{
          padding: 0;
        }
      }
    }
  }
  .sidertabbox_c{
    border: 1px solid #bbd4e8;
    border-top: none;
    .bmore{
      padding: 10px;
      text-align: right;
    }
  }
  .sidertabbox_c_noborder{
    border: 0;
  }
  .sider_links{
    padding: 5px 0;
    li{
      line-height: 32px;
    }
    &.lh1{
      li{
        line-height: 27px;
      }
    }
  }
  .sider_links2{
    padding: 10px 0;
    li{
      line-height: 35px;
    }
    &.lh1{
      li{
        line-height: 27px;
      }
    }
  }  
}