.quotef10{
  .hqadls{
    width: 191px;
    height: 49px;
    background: url(./hq141202ad.png) no-repeat;
    float: left;
    position: relative;
    a{
      display: block;

    }
    .lt{
      position: absolute;
      width: 191px;
      height: 49px;     
      left: 0;
      top: 0; 
      z-index: 1;
    }
    .la{
      position: absolute;
      width: 72px;
      height: 20px;     
      left: 21px;
      top: 23px; 
      z-index: 2;
    }
    .lb{
      position: absolute;
      width: 72px;
      height: 20px;     
      left: 95px;
      top: 23px; 
      z-index: 2;
    }    
  }
  .hqrls{
    float: right;
    background-color: #f2f2f2;
    height: 45px;
    width: 808px;
    padding-top: 5px;
    .cells{
      span{
        font-weight: bold;
        a{
          margin-right: 0;
        }
      }
      line-height: 21px;
      // text-align: right;
      padding-left: 20px;
      a{
        margin: 0 10px 0 0;
      }
      b{
        display: inline-block;
        width: 5px;
        height: 9px;
        margin-left: 2px;
        background: url(./ra.png) no-repeat;
      }
    }
    .cells_hs{
      padding-left: 10px;
      a{
        margin: 0 9px 0 0;
      }      
    }
    .red{
      color: #f00;
    }
  }
}