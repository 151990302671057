@import '../../shared/less/variable.less';

.quote_title{
  border-bottom: 3px solid #2f5895;
  background-color: #f3f3f3;
  padding: 0px 0 0 10px;
  // line-height: 45px;
  height: 45px;
  .quote_title_name{
    font-family: @yhfont;
    font-size: 22px;
    font-weight: 400;
    color: #000;
    vertical-align: middle;
    span{
      vertical-align: -3px;
    }
  }
  .quote_title_name2{
    span{
      vertical-align: 9px;
    }
  }
  .quote_title_link{
    span{
    color: #003497;

    }
    &:hover{
      span{
        color: #ea5404;
      }      
    }

 
  }
  .quote_title_code{
    margin-left: 13px;
    font-family: @yhfont;
    font-size: 15px;
    font-weight: 700;
    color: #000;
    vertical-align: middle;
  }
  .quote_title_tradestate{
    vertical-align: -1px;
    margin-left: 10px;
  }
  .quote_title_tradestate_txt{
    display: inline-block;
    padding-top: 10px;
    vertical-align: -1px;
    margin-left: 10px;
    // margin-right: 10px;
  }  
  .quote_title_time{
    margin-left: 10px;
    vertical-align: middle;
  }
  .quote_title_money{
    margin-left: 13px;
    vertical-align: middle;
  }
  .quote_title_money_help{
    margin-left: 5px;
    width: 14px;
    // height: 20px;
    line-height: 30px;
    display: inline-block;
    position: relative;
    vertical-align: -1px;
    &:hover{
      .quote_title_money_help_tip{
        display: block;
      } 
    }
    .quote_title_money_help_tip{
      display: none;
      border-radius: 3px;
      position: absolute;
      left: -20px;
      top: 28px;
      border: 1px solid #ccc;
      background-color: #fff;
      width: 160px;
      padding: 5px 8px;
      line-height: 25px;
    }
  }

  .zxgbtn{
    margin-left: 15px;
    vertical-align: middle;
  }
  .quote_title_l{
    float: left;
    margin-top: 8px;
  }
  .quote_title_r{
    margin-top: 8px;
    float: right;
  }
  .qtrlink{
    margin: 4px 5px 0 0;
    a{
      display: inline-block;
      margin: 0 5px 0 0;
      padding: 0 10px;
      height: 24px;
      background: #4f78bd;
      color: #fff;
      line-height: 24px;
      &:hover{
        filter: brightness(1.2);
      }
    }
  }
  .quote_title_label{
    font-weight: bold;
    display: inline-block;
    background-color: #5082CC;
    color: #fff;
    margin-left: 8px;
    padding: 4px 8px;
    border-radius: 3px;
    vertical-align: middle;
    &:hover{
      background-color: #3763a5;
    }
  }
  .quote_title_label_rename{
    display: inline-block;  
    position: relative;
    z-index: 12;
    .title{
      display: inline-block;  
      font-weight: bold;
      background-color: #5082CC;
      color: #fff;
      margin-left: 8px;
      padding: 4px 8px;
      border-radius: 3px;
      vertical-align: middle;
      cursor: default;      
    }
    &:hover{
      .renameb{
        display: block;
      }
    }
    .renameb{
      padding-top: 20px;
      display: none;
      position: absolute;
      left: -182px;
      top: 10px;

      .renamebb{
        background: #fff;
        border: 1px solid #aaa;
        padding: 15px;
        width: 400px;
        position: relative;      
      }
      .tt{
        font-size: 14px;
        text-align: center;
        font-weight: bold;
        margin-bottom: 15px;
      }      
      .ret{
        float: left;
        width: 70px;
        text-align: center;
        font-weight: bold;
      }
      .relist{
        float: right;
        width: 330px;
        span{
          display: inline-block;
          text-align: center;
          margin: 0 2px 10px 2px;
          vertical-align: middle;
        }
      }
    }
  }

}



.changes_quote_title{
 .quote_title_code{
   vertical-align: 3px;
 }
 .quote_title_time{
   vertical-align: 4px;
 } 
 .quote_title_label{
   vertical-align: 4px;
 }
 .zxgbtn{
   vertical-align: 4px;
 }
}