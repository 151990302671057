.head_title{
  margin-top: 5px;
  .logo{
    float: left;
    .logolink{
      display: inline-block;
      padding:0 10px 0 8px;
      border-right: 1px solid #C3C3C3;
      img{
        width: 104px;
        height: 26px;
      }
    }
    .logolink2{
      padding: 0 0 0 7px;
      img{
        width: 104px;
        height: 25px;
      }
    }

  }
  .ogo{
    font-size: 14px;
    a{
      display: inline-block;
      margin-top: 6px;
      margin-left: 27px;
      height: 20px;
      line-height: 18px;
    }
    .ogo_qq{
      background: url(./qq.png) no-repeat;
      padding-left: 23px;
    }
    .ogo_data{
      background: url(./data.png) no-repeat;
      padding-left: 23px;
    }
    .ogo_mobile{
      background: url(./mobile.png) no-repeat;
      padding-left: 16px;
    }
    .ogo_app{
      background: url(./app.png) no-repeat;
      padding-left: 18px;
    }
    .ogo_choice{
      background: url(./data16.png) no-repeat;
      padding-left: 23px;
    }
    .ogo_jj{
      background: url(./jj.png) no-repeat 0 3px; 
      padding-left: 16px;
    }


  }
}