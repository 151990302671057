.quote_history{
  margin: 7px 0;
  line-height: 20px;
  height: 20px;
  overflow: hidden;
  padding-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space:nowrap;
  span{
    vertical-align: middle;
  }
  a{
    margin-right: 9px;
  }
  .text_cut{
    vertical-align: middle;
  }
}