.cybzb{
  border-top: 1px solid  #bbd4e8;
  border-bottom: 1px solid  #bbd4e8;
  padding: 7px 0;
  margin-top: 10px;
  ul{
    li{
      display: inline-block;
      margin: 0 15px 0 8px;
    }
  }
  .title{
    font-size: 14px;
    font-weight: bold;
  }
  .icon_tips{
    display: inline-block;
    vertical-align: -2px;
    margin-left: 2px;
    width: 12px;
    height: 12px;
    background: url(../../shared/img/question.png) no-repeat;
    position: relative;
    z-index: 2;
    &:hover{
      .detail{
        display: block;
      }
    }
    .detail{
      position: absolute;
      padding: 10px;
      line-height: 175%;
      width: 260px;
      left: -130px;
      background-color: #fff;
      top: 20px;
      display: none;
      box-shadow: rgba(0, 0, 0, 0.3) 0 0 5px;
    }
  }
}