.quote_hsgt{
  table{
    width: 100%;
    border-collapse: collapse;
    td{
      text-align: center;
      border-right: 1px solid #bbd4e8;
      &:last-child{
        border: none;
      }
    }
  }
  b{
    display: inline-block;
    width: 11px;
    height: 7px;
    margin-right: 3px;
    background: url(./hsgt.gif) no-repeat;
    &.off{
      background: url(./hsgt_off.png) no-repeat;
    }
  }
  .sg{
    font-weight: bold;
    margin-right: 3px;
  }
  .zjlink{
    margin: 0 0 0 3px;
  }
  .nums{
    font-family: Arial;
    font-weight: bold;
  }
}