.guba_title{
  border-bottom: 4px solid #2f5895;
  margin: 15px 0 0 0;
  position: relative;
  .guba_title_t{
    display: inline-block;
    background-color: #fff;
    margin-bottom: -3px;
    padding: 7px 12px;
    font-size: 18px;
    font-family: 'microsoft yahei';
    // position: relative;
    a{
      border-bottom: 2px solid #fff;
    }
    a:link, a:visited{
      color: #000;
    }
    a:hover{
      color: #ea5404;
    }

  }
  .gubatitletg{
    position: absolute;
    left: 130px;
    top: 13px;
    a{
      color: #f00;
      background: url(./zh_222.png) no-repeat 0 0;
      padding-left: 18px;
    }
  }  
  .guba_title_r{
    position: absolute;
    right: 10px;
    top: 12px;
  }
  &:before{
    content: '';
    width: 7px;
    height: 7px;
    position: absolute;
    left: 0;
    top: 0;
    border-left:3px solid #FC4802;
    border-top:3px solid #FC4802;
  }
  .guba_icon{
    display: inline-block;
    background: url(./guba.png);
    width: 14px;
    height: 14px;
    vertical-align: -3px;
  }
}

.guba_kcb_title{
  margin-top: 10px;
   &:before{
     display: none;
   }
  .guba_title_kcb{
    font-size: 18px;
    font-family: 'microsoft yahei';
    display: inline-block;
    border: 1px solid #2f5895;
    padding: 0px 15px;
    height: 34px;
    line-height: 34px;
    a{
      color: #494949;
      &:hover{
        color: #ea5404;
      }
    }
    position: relative;
    bottom: -2px;
    background-color: #fff;
  } 
  .guba_title_kcb2{
    border: 1px solid #BBD4E8;
    border-left: 0;
    position: relative;
    bottom: -1px;
    height: 32px;
    line-height: 32px;
  }
}