.xgbk{
  height: 171px;
  table{
    border-collapse: collapse;
    width: 100%;
    th, td{
      padding: 0;
    }
    th{
      font-weight: normal;
      height: 27px;
      text-align: center;
    }
    td{
      height: 28px;
      // padding-left: 5px;
      text-align: center;
    }
  }
  .nodata{
    text-align: center;
    padding-top: 80px;
  }
}

.bkstocksbar{
  margin-top: 10px;
  border: 1px solid #eee;
  height: 50px;
  .t{
    background-color: #eee;
    font-weight: bold;
    width: 30px;
    float: left;
    height: 45px;
    text-align: center;
    line-height: 21px;
    padding: 5px 5px 0px 5px;
    overflow: hidden;
    a{
      color: #494949;
      &:hover{
        color: #ea5404;
      }
    }
  }
  .list{
    width: 525px;
    float: right;
    ul{
      padding-top: 5px;
    }
    li{
      float: left;
      list-style: none;
      margin: 3px 0;
      width: 173px;
    }
  }
}

.bkstockrank{
  height: 170px;
  .nodata{
    text-align: center;
    padding-top: 90px;
  }
  .sel{
    text-align: center;
    height: 25px;
    padding-top: 8px;
  }
  .c{
    table{
      border-collapse: collapse;
      width: 100%;
      th,td{
        padding: 0;
        text-align: left;
        padding-left: 5px;
      }
      th{
        font-weight: normal;
        height: 19px;
      }
      td{
        height: 23px;
      }
    }
  }

}