.sider_quote_price{
  height: 290px;
  .wb{
    text-align: center;
    border-bottom: 1px solid #BBD4E8;
    line-height: 22px;
  }
  .mm{
    table{
      border-collapse: collapse;
      width: 100%;
      td{
        height: 20px;
        padding-left: 4px;
      }
      .dkgg{
        text-align: center;
        height: 20px;
        a{
          color: #f00;
          font-weight: bold;
        }
      }
      .topb{
        border-top: 1px solid #D4E5F3;
      }
      .botb{
        border-bottom: 1px solid #D4E5F3;
      }
    }
  }
}

.sider_quote_price2{
  .mm{
    table{
      td{
        height: 22px;
      }
    }
  }
}

.zs_zjl{
  height: 290px;
  table{
    width: 100%;
    border-collapse: collapse;
    
    td{
      height: 23px;
    }
    th{
      font-weight: normal;
      height: 18px;
    }

    .tar{
      text-align: right;
       padding-right: 10px;
    }
  }

  .zjl1{
    border-bottom: 1px solid #BBD4E8;
  }
}

.zjl1{
      tr>td:first-child{
      padding-left: 10px;      
    }
}

.zjlchart{
  margin-top: 10px;
  height: 79px;
  .uptr{
    td{
      border-bottom: 1px solid #CCCCCC;
      vertical-align: bottom;
    }
  }
  span{
    display: block;
  }  
  .downtr{
    td{
      vertical-align: top;
    }
  }

  td{
    text-align: center;
    span{
      margin: 0 auto;
    }
  }
}

.bjtg{
  text-align: center;
  a{
    color: #f00;
    font-weight: bold;
  }
}